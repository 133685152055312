// src/services/translationService.ts

import axios from 'axios';

/**
 * Detects the language of the given text using Microsoft Translator API.
 * @param text The text to detect the language for.
 * @returns The detected language code or null if detection fails.
 */
export const detectLanguage = async (text: string): Promise<string | null> => {
  const subscriptionKey = "0095493be82e4538bd3ffcae561b2593";
  const endpoint = "https://api.cognitive.microsofttranslator.com";
  const location = "westeurope";

  try {
    const response = await axios({
      baseURL: endpoint,
      url: '/detect',
      method: 'post',
      headers: {
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'Ocp-Apim-Subscription-Region': location,
        'Content-type': 'application/json',
      },
      params: {
        'api-version': '3.0',
      },
      data: [
        { text },
      ],
      responseType: 'json',
    });

    const detectedLang = response.data[0]?.language;
    //console.log("Detected language:", detectedLang);
    return detectedLang;

  } catch (error) {
    //console.error("Error detecting language:", error);
    return null;
  }
};

/**
 * Translates the given text to the target language using Microsoft Translator API.
 * @param text The text to translate.
 * @param targetLanguage The target language code.
 * @returns The translated text or null if translation fails.
 */
export const translateText = async (text: string, targetLanguage: string): Promise<string | null> => {
  const subscriptionKey = "0095493be82e4538bd3ffcae561b2593";
  const endpoint = "https://api.cognitive.microsofttranslator.com";
  const location = "westeurope";

  try {
    const response = await axios({
      baseURL: endpoint,
      url: '/translate',
      method: 'post',
      headers: {
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'Ocp-Apim-Subscription-Region': location,
        'Content-type': 'application/json',
      },
      params: {
        'api-version': '3.0',
        'to': targetLanguage,
      },
      data: [
        { text },
      ],
      responseType: 'json',
    });

    const translatedText = response.data[0]?.translations[0]?.text;
    //console.log("Translated text:", translatedText);
    return translatedText;

  } catch (error) {
    //console.error("Error translating text:", error);
    return null;
  }
};
