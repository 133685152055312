// rowKeyService.ts
import { useContext } from "react";
import { BackendContext } from "../../context";

// Our custom Hook to fetch a rowKey from the backend
export function useRowKeyService() {
  // 1) Safely call a Hook *here* (in a function component or custom hook!)
  const ps = useContext(BackendContext);
  if (!ps) {
    throw new Error("Missing BackendContext. Have you wrapped your app?");
  }

  // 2) Return an async function that knows how to fetch the rowKey
  return async function fetchRowKey(country: string): Promise<string> {
    const token = await ps.acquireToken();
    const url = `${ps.base}/api/get_row_key?country=${encodeURIComponent(
      country
    )}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (!response.ok) {
      console.error("Error fetching rowKey:", data.error || "Unknown error");
      // fallback rowKey
      return "6318c95313174a97bc14aed97a24da7f";
    }

    return data.rowKey;
  };
}
